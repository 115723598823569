<template>
  <PageWrapper :page-data="pageData"
               :brand="brandFromStore">
    <template #header-logo>
      <HeaderLogo />
    </template>
    <template #header-user-navi>
      <HeaderUserNavi />
    </template>
    <template #content-top>
      <StarsignSlider :slider-options="sliderOptions" />
    </template>
    <template #footer>
      <PageFooter :brand="brandFromStore"
                  :footer-data="pageData.footer" />
    </template>
    <template #loading-overlay>
      <LoadingOverlay />
    </template>
  </PageWrapper>
</template>

<script>
import { mapState } from 'pinia'

import { usePageStore } from '../../stores/page'
// these components will be injected into the app via slots
import PageWrapper from '../../components/PageWrapper.vue'
import HeaderUserNavi from '../../components/shared/HeaderUserNavi.vue'
import defaultAppInit from '../../mixins/default-app-init'
import PageFooter from './theme/Footer.vue'
import HeaderLogo from './theme/HeaderLogo.vue'
import LoadingOverlay from './theme/LoadingOverlay.vue'
import StarsignSlider from './theme/StarsignSlider.vue'

export default {
  components: {
    HeaderLogo,
    PageFooter,
    LoadingOverlay,
    HeaderUserNavi,
    PageWrapper,
    StarsignSlider
  },
  mixins: [defaultAppInit],
  computed: {
    ...mapState(usePageStore, ['pageData', 'brandFromStore']),
    sliderOptions () {
      return {
        lazyLoad: 12
      }
    }
  }
}
</script>
